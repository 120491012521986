/* Provide sufficient contrast against white background */
body {
  margin: 0;
}
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.border-bottom-bar {
  border-bottom: 2px solid rgb(243,243,243);
}

.DOC_WEB_app_header {
  z-index: auto !important;
}

.ABB_OTC_app_bar {
  flex: none;
  display: block;
  height: auto;
  padding-left: 10px;
  background: white !important;
}

.ABB_OTC_app_content_container {
  margin-top: 15px;
}

.DOC_OTC_left_panel,
.DOC_OTC_right_panel {
  height: 100%;
  flex: auto;
}

.DOC_OTC_left_panel {
  margin-left: 10px;
}

.DOC_OTC_setup_page {
  width: 60%;
  height: fit-content;
}

.DOC_OTC_advanced_cooling_area {
  width: 400px;
}


.DOC_OTC_results_page {
  width: 50%;
  height: fit-content;
}
.DOC_OTC_advanced_options {
  width: 30% !important;
  height: fit-content;
}

.DOC_OTC_left_panel .DOC_OTC_left_panel_content {
  background: white;
  height: 85vh;
}

.DOC_OTC_right_panel {
  height: auto;
}

.DOC_OTC_right_panel_content {
  margin: 5px;
  margin-top: 4px;
}

/* Input styles */
.DOCweb_CommonUX_Label_Centered_for_ABB_Inputs {
  margin-top: 25px;
  text-align: center;
}

.object-properties-error-message {
  /* color: $abb-alarm-red; */
  color: #F03040;
}

.object-properties-error-message label {
  width: 95%;
  text-align: center !important;
}

.label-radio,
.label-checkbox-header,
.label-checkbox {
    /* text-align: center; */
    text-align: left;
    border-right: none;
    padding-left: 0px;
    padding-right: 0px;
}

.label-radio {
  /* height: 100%; */
  height: auto;
  /* min-height: 33px; */
  min-height: auto;
}

.DOCWeb_project-explorer-pages-dragging > .ABB_CommonUX_Input__inputWrapper > .object-properties-input-field {
  border-top: 1px solid #bababa !important;

}

.DOCWeb_project-explorer-input > .ABB_CommonUX_Input__inputWrapper > .object-properties-input-field {
  border-top: 1px solid #bababa !important;
}

.DOCweb_CommonUX_Select__custom:disabled {
  background: #ebebeb;
}

.DOCweb_CommonUX_Select__custom {
  border: 1px solid #bababa !important;
  color: #1f1f1f;
  background: #FFFFFF;
  height: 32px !important;
  width: 100%;
  padding: 4px 36px 4px 8px;
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  box-shadow: none;
  outline: none;
  font-family: ABBvoice;
  cursor: pointer;
  -webkit-appearance: none;
}
.DOCweb_CommonUX_Select__custom:hover {
  background-color: #ebebeb;
  border: 1px solid #9f9f9f;
}

.DOCweb_CommonUX_Select__wrapper {
  position: relative;
}
.DOCweb_CommonUX_Select__wrapper:after  {
  font-family: 'ABB-UI-Icons-16x16' !important;
  content: '\ea08';
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  right: 4px;
  pointer-events: none;
  line-height: 1;
}

.editable-input {
  width: calc(100% - 25px);
  height: 100%;
  margin-top: -36px;
}
.editable-input.ABB_CommonUX_Input__root.ABB_CommonUX_Input__light.ABB_CommonUX_Input__normal > .ABB_CommonUX_Input__inputWrapper > input {
  border-right: none;
}

.main-grid {
  margin-left: 50px;
  margin-top: 10px;
  margin-right: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-60 {
  margin-top: 100px;
}

.margin-bottom-20 {
  margin-bottom: 9px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.project-name {
  margin-bottom: 10px;
  margin-left: 10px;
}

.label-main {
  margin-top: 5px;
  margin-bottom: 5px;
}

.radio-style {
  margin-top: 8px;
  margin-bottom: 8px;
}

.right-panel {
  margin: 0px 50px 0 30px;
  line-height: 2;
}

.display-none {
  display: none !important;
}

.print-grid {
  margin-bottom: 20px ;
}

.input-right-panel {
  background: #ebebeb !important;
  border: 0 !important;
}

.label-effective-cooling-area {
  color: #696969;
}

.label-results-page {
  font-weight: bold;
}

.OTC_DOC_SVGChart {
}

.OTCWEB_display-text-aligment {
  text-align: right;
}

.ABB_CommonUX_Input__root>.ABB_CommonUX_Input__description {
  color: #F03040 !important;
}

.mobile-main-grid {
    margin-left: 16px;
    margin-top: 10px;
    margin-right: 16px;
}

.mobile-ventilation {
  margin-top: 30px;
  margin-bottom: 36px;
}

.mobile-right-panel {
  margin-left: 31px;
}

.mobile-setup-panel {
  margin-left: 14px;
  background-color: white;
}

.mobile-button {
  margin-left: -30px;
    margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
}

.mobile-ambient-t {
  margin-top: 20px;
  margin-bottom: 24px;
}

.disposition-picture {
  max-width: 500px;
  padding-top: 10px;
}
.mobile-chart {
  margin-left: 10px;
}

.main-page-container {
  overflow-y: scroll;
}

.label-warning-message {
  color: red;
  text-align: center;
  display: block;
}

h1 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.advanced-options-height {
 height: 83vh !important;
}